<template src="./EditResearchGroup.html"></template>

<script>
import categoryService from "@/services/Category";
import { putFile } from "@/services/files";
import idiService from "../../services/Idi";
import ModalDescriptionFile from "../../components/ModalDescriptionFile/ModalDescriptionFile";
import { required } from "vuelidate/lib/validators";

export default {
  name: "EditResearchGroup",
  components: {
    ModalDescriptionFile,
  },
  data() {
    return {
      creationRequest: {},
      categories: [],
      institutionId: "",
      showModalImageDescription: false,
      update: false,
      imageFile: null,
    };
  },
  validations: {
    creationRequest: {
      category: { required },
      description: { required },
      institutionName: { required },
      coordinator: { required },
      name: { required },
    },
    // imageFile: { required },
  },
  created() {
    this.getInfoCategories();
    this.getGroup();
  },
  methods: {
    deleteFile() {
      this.imageFile = null;
    },
    async getInfoCategories() {
      try {
        this.categories = await categoryService.getCategories();
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message: e.response?.data?.message ?? "Se presentó un error",
          position: "is-bottom",
          type: "is-danger",
        });
        console.error("Error: EditResearchGroup/getInfoCategories()", e);
      }
    },
    async updateGroup() {
      this.$store.dispatch("fetchLoading", true);
      if (this.imageFile) {
        try {
          const urlImage = await putFile(
            this.imageFile,
            "CreateResearchGroup",
            `${this.imageFile.name}`
          );
          this.creationRequest.fileImg = urlImage;
        } catch (e) {
          this.$buefy.toast.open({
            duration: 5000,
            message: "Error cargando el archivo",
            position: "is-bottom",
            type: "is-danger",
          });
        }
      }
      try {
        const obj = {
          name: this.creationRequest.name,
          description: this.creationRequest.description,
          registrationNumber: this.creationRequest.registrationNumber ? this.creationRequest.registrationNumber : "",
          institutionId: this.creationRequest.institutionId,
          coordinatorId: this.creationRequest.coordinator.id,
          institutionName: this.creationRequest.institutionName,
          categoryId: this.creationRequest.category.id,
          fileImg: this.creationRequest.fileImg,
          descriptionImg: this.creationRequest.descriptionImg,
        };
        await idiService.updateGroup(this.$route.params.id, obj);
        this.$store.dispatch("fetchLoading", false);
        this.$buefy.toast.open({
          duration: 5000,
          message: "Grupo actualizado",
          position: "is-bottom",
          type: "is-success",
        });
        this.$router.push({ path: `idi/info/${this.$route.params.id}` });
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message: e.response?.data?.message ?? "Se presentó un error",
          position: "is-bottom",
          type: "is-danger",
        });
        console.error("Error: EditResearchGroup/sendRequest()", e);
      }
    },
    async getGroup() {
      try {
        this.creationRequest = await idiService.listOneGroup(
          this.$route.params.id
        );
        this.update = true;
        this.institutionId = this.$store.state.User.user.institutionId.name;
      } catch (error) {
        console.error(error);
      }
    },
    openModalImageDescription() {
      this.showModalImageDescription = true;
    },
    closeModalDescriptionImage() {
      this.showModalImageDescription = false;
    },
    uploadFileInfo(info) {
      this.creationRequest.descriptionImg = info.description;
      this.imageFile = info.file;
      this.showModalImageDescription = false;
    },
  },
};
</script>

<style lang="scss" scoped src="./EditResearchGroup.scss">

</style>